import React from "react";
import { Table, Header } from "semantic-ui-react";

const CategoryPermissions = ({ rows }) => {
  return (
    <>
    {rows && <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Category</Table.HeaderCell>
            <Table.HeaderCell width={2}>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.name}</Table.Cell>
                  <Table.Cell>{row?.value}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      </>    }
    </>
    )
};

export default CategoryPermissions;