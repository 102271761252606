import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import OnBoarding from './onboarding';


function Organizations() {
  const [showHeaderTxt, setShowHeaderTxt] = useState(true);

  return (
    <>
      <Routes>
        <Route 
          path="/" 
          element={
            <List setShowHeaderTxt={setShowHeaderTxt} />} 
        />
        <Route 
          path="details/:id/*" 
          element={
            <Details setShowHeaderTxt={setShowHeaderTxt} />} 
        />
        <Route
          path="details/:id/onboarding/*"
          element={
            <OnBoarding setShowHeaderTxt={setShowHeaderTxt} />} 
        />
      </Routes>
    </>
  );
}

export default Organizations;