import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table } from 'semantic-ui-react'

import { format } from 'date-fns'

const ActivityLogTable = ({ rows }) => {
    const navigate = useNavigate()

    return (
        <>
            <Table selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Task Id</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Start</Table.HeaderCell>
                        <Table.HeaderCell width={2}>End</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i} onClick={() => navigate(`/app/activity-log/details/${row?.id}`)}  style={{cursor: 'pointer'}}>
                            <Table.Cell>{row?.id}</Table.Cell>
                            <Table.Cell>{row?.name}</Table.Cell>
                            <Table.Cell>{row?.status}</Table.Cell>
                            <Table.Cell>{row?.task_id}</Table.Cell>
                            <Table.Cell>
                            {row?.created_at && format(new Date(row?.created_at), 'MMM. dd, yyyy h:mm a')}
                            </Table.Cell>
                            <Table.Cell>
                            {row?.updated_at && format(new Date(row?.created_at), 'MMM. dd, yyyy h:mm a')}
                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

ActivityLogTable.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default ActivityLogTable;

