import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Header } from "semantic-ui-react";

const CategoryTags = ({ values, setCategorySearch, valueIdName }) => {
  return (
    <>
          <Header as="h2">
            Category Filter
          </Header>
          <ContainerBox>
            {values &&
              values.map((n, i) => {
                return (
                  <ContainerFieldSelectable key={i} onClick={() => setCategorySearch({id: n?.name, name: valueIdName?.[n?.name]})}>
                     {valueIdName?.[n?.name]} <NumberDisplay>({n?.no_of})</NumberDisplay>
                  </ContainerFieldSelectable>
                );
              })}
          </ContainerBox>
          </>
  );
};

CategoryTags.propTypes = {
  names: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

export default CategoryTags;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ContainerField = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const NumberDisplay = styled.span`
    padding-left: 5px;
    font-weight: 600;
`