import React, { useEffect, useState } from "react";

import CategoryPermissions from "./Details";

// import AuthDetails from "./AuthDetails";
// import ExtraDetails from "./ExtraDetails";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_PERMISSIONS } from "constants/actions";
import { Card } from 'semantic-ui-react'

const EmployeePermission = ({ employeeId }) => {
  const dispatch = useDispatch();

  const [details, setDetails] = useState([]);
  //  const [authDetails, setAuthDetails] = useState([]);
  //  const [extraDetails, setExtraDetails] = useState([]);

  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${employeeId}`,
      });
    }
  }, [dispatch, employeeId]);

  const { get_employee_permission } = useSelector(
    (state) => ({
      get_employee_permission: state.employee_permission.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    setDetails(get_employee_permission?.[0]?.details);

    /*    setAuthDetails(get_employee_permission?.[0]?.auth_details);
    
        if (get_employee_permission?.[0]?.extra_details) {
          const extra_details = Object.entries(
            get_employee_permission?.[0]?.extra_details
          );
          setExtraDetails(extra_details);
        }
    */

  }, [get_employee_permission]);

  return (
    <>
      {/* Todo: TO Not sure what this does, will need to check the API. Not returning and data. Deprecated? Remove?
      <AuthDetails header={"Auth Details"} rows={authDetails} />
*/}

      {details?.length > 0 ?
        <CategoryPermissions header={"Category Permissions"} rows={details} /> :
        <NoRecords message={"No category permissions"} />
      }




      {/* Todo: TO Not sure what this does, will need to check the API. Not returning and data. Deprecated? Remove?
      <ExtraDetails header={"Extra Details"} rows={extraDetails} />
*/}
    </>
  );
};

export default EmployeePermission;

const NoRecords = ({ message }) => {
  return (<>
      <Card>
          <p style={{ padding: '20px' }}>{message}</p>
      </Card>
  </>)
}