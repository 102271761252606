import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import List from './List/Index.js';
import Details from './Detail/Index.js';
import { Header, Icon } from 'semantic-ui-react'

function CategoryStructure() {

  const [orgName, setOrgName] = useState(false)

  return (
    <>
      {ShowArrow(orgName)}
      <Routes>
        <Route path="/" element={<List setOrgName={setOrgName} />} />
        <Route path="details/:id" element={<Details setOrgName={setOrgName} />} />
        <Route path="create" element={<Details setOrgName={setOrgName} />} />
      </Routes>
    </>
  );
}

export default CategoryStructure;

function ShowArrow(orgName) {
  const navigate = useNavigate();
  function BackArrow() {
    navigate('/app/categories/')
  }
  if (orgName) {
    return (
      <Header as='h2' style={{ marginTop: "20px" }}>
        <Icon style={{ cursor: "pointer" }} onClick={BackArrow} name='arrow left' /> 
        {orgName} Survey Categories
        <Header.Subheader>
          Manage category structure for surveys
        </Header.Subheader>
      </Header>
    )
  }
  return (
    <Header as='h2' style={{ marginTop: "20px" }}>
      Organization Categories 
      <Header.Subheader>
          Assign categories to an organization's suveys
        </Header.Subheader>
    </Header>



  )
}