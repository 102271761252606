import React, { useState } from "react";
import {  Label } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Input from "./InputLogin";
import Logo from "assets/images/nav/nuwave_black.png";
import NuWave from "assets/images/nav/nuwave_black.png";
import styled from "styled-components";
import { WEBSITE_RESET_PASSWORD } from "constants/actions";

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;


const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  height: 75px;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 30px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Red Hat Display", sans-serif;
  justify-content: space-between;
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;

const ResetPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { handleSubmit, control, watch, errors } = useForm();
  const [domain] = useState(window.location.hostname);

  const [token, setToken] = React.useState("");
  const search = window.location.search;
  
  // const token = ;

  React.useEffect(() => {
    const params = new URLSearchParams(search);
    setToken(params.get("token"));
  }, [search]);

  const { get_error } = useSelector(
    (state) => ({
      get_error: state.errors,
    }),
    shallowEqual
  );

  const onSubmit = (data) => {
    dispatch({
      type: WEBSITE_RESET_PASSWORD,
      payload: { ...data, token },
    });
    navigate("/account/login");
  };

  let pwd = watch("password");
  return (
    <Container>
      {token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Banner>
              <IMG src={domain === "nu.innerlogic.com" ? NuWave : Logo} />
            </Banner>
            <Title>Password Reset</Title>
            <Description>
              Please enter a new password for your account
            </Description>
            {get_error?.errors?.data?.status && (
              <Label basic color="red" pointing="below">
                {get_error?.errors?.statusText}
              </Label>
            )}

            <Controller
              as={Input}
              name="password"
              placeholder="Password"
              control={control}
              type="password"
              defaultValue=""
              rules={{
                required: "Please enter Password",
                validate: (value) =>
                  value.length > 7 ||
                  "The min length of password is 8 characters",
              }}
              error={errors.password ? errors.password.message : ""}
            />

            <Controller
              as={Input}
              name="password_2"
              placeholder="Repeat Password"
              control={control}
              type="password"
              defaultValue=""
              rules={{
                required: "Please enter Password Again",
                validate: (value) =>
                  value === pwd || "The passwords do not match",
              }}
              error={errors.password_2 ? errors.password_2.message : ""}
            />

            <Button type="submit">Submit</Button>
          </Box>
        </form>
      ) : (
        <Box>
          <Banner>
            <IMG src={Logo} />
          </Banner>
          <Title>Check your Email</Title>
          <Description>
            If the email you entered is found in our records we would shortly
            send you an email with password reset instructions
          </Description>

          <Bottom>
            <div>
              <Link to="/account/login">Login</Link>
            </div>
          </Bottom>
        </Box>
      )}
    </Container>
  );
};

export default ResetPassword;
