import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { useNavigate, useLocation } from 'react-router';
import { Button } from 'semantic-ui-react'

const Sequence = [
    {
        no: 1,
        name: "Home",
        value: "home",
        link: ""
    },
    {
        no: 2,
        name: "Surveys",
        value: "organization-surveys",
        link: "onboarding/organization-surveys"
    },    
    {
        no: 3,
        name: "Rules",
        value: "organization-rules",
        link: "onboarding/organization-rules"
    },
    {
        no: 4,
        name: "Categories",
        value: "organization-categories",
        link: "onboarding/organization-categories"
    },
    {
        no: 5,
        name: "Styles",
        value: "organization-styles",
        link: "onboarding/organization-styles"
    },

    {
        no: 6,
        name: "Questions",
        value: "questions",
        link: "onboarding/questions"
    },
    {
        no: 7,
        name: "Survey Demo",
        value: "emails",
        link: "onboarding/emails"
    }
]

const NavigationBar = ({organizationId}) => {
    const navigate = useNavigate()
    const location = useLocation()
    let urlElements = location.pathname.split('/')

    const [urlLocation, setUrlLocation] = useState(false)

    useEffect(() => {
        setUrlLocation('home')
        const LastElement = urlElements[urlElements.length - 1]

        if (!Number.isInteger(Number(LastElement))) {
            setUrlLocation(LastElement)
        }

    }, [urlElements]);

 
    const Link = `/app/organizations/details/${organizationId}`

    if (!organizationId) {
        navigate("/app/organizations")
    }

    return (
    <TopBar>
        {Sequence.map((bx, i) => {
            return (
                <BarBoxes key={i}>
                    <Button
                        color={bx.value === urlLocation ? 'violet' : 'grey'}
                        onClick={() =>
                            navigate(`${Link}/${bx.link}`)}>
                        {bx.name}
                    </Button>
                </BarBoxes>
            )
        })}
    </TopBar>
    );
};

export default NavigationBar;

const TopBar = styled.div`
  padding: 20px 0px 0px 0px;
  margin:10px 0px;
    display: flex; 
    justify-content: flex-start
`

const BarBoxes = styled.div`
    padding: 5px;
    margin: 5px;
`