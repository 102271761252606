import Logo from "assets/images/nav/nuwave_black.png";
import FullLogo from "assets/images/nav/nuwave_black.png";
import NuWave from "assets/images/nav/nuwave_black.png";

// SUB_DOMAIN_NAMES = [
//     (1, 'app.innerlogic.com'),  # game
//     (2, 'caatcanada.ca'),  # Specific Org
//     (3, 'app.nulogic.co'),  # External Partner
// ]

export const Domain = {
    id: 1,
    name: "Nulogic",
    url: "app.nulogic.co",
    image: Logo
}

export const DomainList = {
    "app.nulogic.co": {
        id: 1,
        name: "nuLogic",
        url: "app.nulogic.co",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to nuLogic",
        allowRegister: false,
        registerText: "Don't have a login ",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/privacy.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",

    },
    "admin.nulogic.co": {
        id: 1,
        name: "nuLogic",
        url: "admin.nulogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to nuLogic",
        allowRegister: false,
        registerText: "Don't have a login ",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/privacy.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",

    },
    "localhost:3000": {
        id: 1,
        name: "nuLogic",
        url: "localhost:3000",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to nuLogic (localhost)",
        allowRegister: true,
        registerText: "Don't have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/privacy.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "localhost:3001": {
        id: 1,
        name: "nuLogic",
        url: "localhost:3000",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to nuLogic (localhost)",
        allowRegister: false,
        registerText: "Don't have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/privacy.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "portal.nulogic.co": {
        id: 3,
        name: "nuLogic",
        url: "portal.nulogic.co",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to nuLogic",
        allowRegister: true,
        registerText: "Don't have a login? ",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: false,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/privacy.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
/*    "stg-app.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Staging App)",
        allowRegister: true,
        registerText: "Do not have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "stg-admin.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Staging App)",
        allowRegister: true,
        registerText: "Do not have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "demo-app.innerlogic.ca": {
        id: 1,
        name: "Innerlogic",
        url: "demo-app.innerlogic.ca",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to access your innerlogic account (Production Login)",
        allowRegister: true,
        registerText: "Do not have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: false,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    */

}