import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_STRUCTURES } from "constants/actions";

// Components
import styled from "styled-components";
import { Header, Card } from 'semantic-ui-react'
import CategoryRowCard from "categories/List/RowCard"


const OrganizationCategories = ({ organizationId }) => {

    const dispatch = useDispatch();

    // extract data from the Redux store
    const get_survey_structure = useSelector(
            (state) => (state.surveystructure.survey_structure),
            shallowEqual
        );

    // create a new state variable, its setter, and its initial state
    const [results, setResults] = useState(get_survey_structure?.results);

    // assign Redux data to state variable when page state changes
    useEffect(() => {
        setResults(get_survey_structure?.results);
    }, [get_survey_structure]);

    // reload survey structure to get current value of survey structure
    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_SURVEY_STRUCTURES,
                payload: `organization=${organizationId}`,
            });
        }
    }, [dispatch, organizationId]);

    return (
        <>
            <Header as='h2'>
                Survey Categories
            </Header>
            <Header.Subheader>
                Survey categories configured for this organization
            </Header.Subheader>

            <SectionDiv>
            { results && results?.length ? (     
                <Card.Group>
                    {
                        results && results.map((result) => (
                            <CategoryRowCard row={result} key={result?.id} />
                        )) 
                    }
                </Card.Group>
                ) : (
                    <Header.Subheader>*** No categories have been configured for this organization ***</Header.Subheader>
                )
            }
            </SectionDiv>
        </>
    );
};

export default OrganizationCategories

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  min-height: 110px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};
`
