import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { UPDATE_ORGANIZATION } from "constants/actions";

// Components
import { RulesSubList } from "../Components";
import styled from "styled-components";
import { Button, Header } from 'semantic-ui-react';

const OrganizationRules = ({ organizationId }) => {

    const { get_organizations } = useSelector(
        (state) => ({
            get_organizations: state.organizations,
        }),
        shallowEqual
    );
    
    const [stylingList, setStylingList] = useState(get_organizations?.[organizationId]?.styling)
     const [updateValue, setUpdateValue] = useState(false)

    const dispatch = useDispatch();

    function UpdateDetails() {
        setUpdateValue(false)
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    styling: stylingList
                }
            });
        }
    }

    useEffect(() => {
        setStylingList(get_organizations?.[organizationId]?.styling)
    }, [get_organizations, organizationId]);


    return (
        <>
            <Header as='h2'>
                Survey Rules
            </Header>
            
            <SectionDiv>
                <Header.Subheader>
                    Specify the number of follow-up questions to ask a user about their highest and lowest survey scores
                </Header.Subheader>
                <SectionDiv>
                    <RulesSubList
                        stylingList={stylingList}
                        setStylingList={setStylingList}
                        setUpdateValue={setUpdateValue}
                    />
                    {stylingList?.rules &&
                        <Button
                            color='violet'
                            onClick={UpdateDetails}
                            disabled={!updateValue}>Update</Button>
                    }
                </SectionDiv>
            </SectionDiv>
        </>
    );
};

export default OrganizationRules;


const SectionDiv = styled.div`
  padding: 0px 0px;
  margin:10px 0px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  margin-top: 30px;
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};
`
