import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from 'date-fns'

import { Modal, Header, Button, Icon, Table } from 'semantic-ui-react'

import ReactJson from "react-json-view";

const RowCard = ({ row, empUpdateRow, setEmpUpdateRow }) => {
  //  empUpdateRow={true} setEmpUpdateRow={setEmpUpdateRow}
  const [openPortal, setOpenPortal] = useState(false)
  const [showResponse, setShowResponse] = useState(false)
  const [briefSummary, setBriefSummary] = useState(false)
  // const [briefToken, setbriefToken] = useState(false)
  function ShowResponse(resp) {
    // setbriefToken(id)
    setOpenPortal(true)
    setShowResponse(resp)
  }
  function ShowSummary(resp) {
    // setbriefToken(id)
    setOpenPortal(true)
    setBriefSummary(resp)
  }

  return (
    <>
      <Table.Row onClick={() => ShowResponse(row?.response)} style={{ cursor: 'pointer' }}>
        <Table.Cell>{row?.updated_at && format(new Date(row?.updated_at), 'MMM. dd, yyyy hh:mm a')}</Table.Cell>
        <Table.Cell>{row?.type_of_text}</Table.Cell>
        <Table.Cell><TypesAndStatusArray serviceUsed={row?.response?.feedback} /></Table.Cell>
        <Table.Cell>{row?.survey_token}</Table.Cell>
        <Table.Cell>{row?.id}</Table.Cell>
      </Table.Row>

      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        briefResponse={showResponse}
        briefSummary={briefSummary}
      />
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

function TypesAndStatus({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed ?
        <Icon color='green' name='checkmark' size='small' />
        :
        <Icon color='red' name='close' size='small' />
      }
    </>
  )
}

function TypesAndStatusArray({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed.length > 0 ?
        <><Icon color='green' name='checkmark' size='small' />Yes</>
        :
        <><Icon color='red' name='close' size='small' />No</>
      }
    </>
  )
}

const MoreDetails = ({ openPortal, setOpenPortal, briefResponse, briefSummary }) => {

  return (<Modal
    closeIcon
    open={openPortal}
    onClose={() => setOpenPortal(false)}
    onOpen={() => setOpenPortal(true)}
  >
    <Header>Detailed Response</Header>
    <Modal.Content>

      {briefResponse &&
        <ReactJson src={briefResponse} />
        // <ContentDetail briefResponse={briefResponse} />
      }
      {briefSummary &&
        <ReactJson src={briefSummary} />
        // <Summary briefChanggeHistory={briefSummary} />
      }
    </Modal.Content>
    <Modal.Actions>

      <Button color='purple' onClick={() => setOpenPortal(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  padding: ${(props) => props.highlight ? '5px' : '0px'};
  // background-color: #dcdcdcdc;
  background-color: ${(props) => props.highlight ? '#dcdcdcdc' : ''};
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`