import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Button, Header, Image, Modal } from 'semantic-ui-react'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import Select from "react-select";


import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    PULL_ORGANIZATION,
    UPDATE_ORGANIZATION,
    SG_GET_SURVEY_STRUCTURES,
    SG_GET_SURVEY_QUESTION_SELECT,
    SG_GET_ALL_SURVEY_QUESTION_SELECT,
    SG_GET_ORGANIZATION_STYLE,
} from "constants/actions";

import UpdateOrg from "./UpdateOrg"

import { SUB_DOMAIN_NAMES, ORG_PAID_STATUS } from "data/AdminData"
import { CommonTimezones } from "data/timezone";
import OnBoarding from './onboarding/index';
//import Questions from './onboarding/Questions';
//import CategoryQuestions from './CategoryQuestions';
//
const Details = ({ setShowHeaderTxt }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [openOrgEdit, setOpenOrgEdit] = React.useState(false)

    const [organizationId, setOrganizationId] = useState(null);
    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [organizationStylingSelect, setOrganizationStylingSelect] = useState([]);
//    const [domainSelected, setDomainSelected] = useState(null)
//    const [paymentStatusSelected, setPaymentStatusSelected] = useState(null)
    const [serviceList, setServiceList] = useState([])
//    const [updateValue, setUpdateValue] = useState(false)
    const [stylingList, setStylingList] = useState([])

    const [orgIndustryCategory, setOrgIndustryCategory] = useState([])
    //const [updateIndustryValue, setUpdateIndustryValue] = useState(false)

    const [stylingMsg, setStylingMsg] = useState(false)
    const [timeZone, setTimeZone] = useState({
        value: "US/Eastern",
        label: "US/Eastern",
        text: "US/Eastern",
    });

    const [results, setResults] = useState([]);

    function BackArrow() {
        navigate("/app/organizations")
    }

    useEffect(() => {
        setOrganizationId(id)
    }, [id]);

    useEffect(() => {
        setShowHeaderTxt(false)
    }, [setShowHeaderTxt]);

    const utcDateTime = utcToZonedTime(new Date(), 'UTC')
    const [localDateTime, setLocalDateTime] = useState(zonedTimeToUtc(new Date(), 'US/Eastern'))

    useEffect(() => {
        if (organizationDetails?.time_zone) {
            const date = new Date()
            const timeZone = organizationDetails?.time_zone
            const zonedDate = utcToZonedTime(date, timeZone)
            setLocalDateTime(zonedDate)
        }
    }, [organizationDetails]);


    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: PULL_ORGANIZATION,
                payload: { id: organizationId }
            });
            dispatch({
                type: SG_GET_SURVEY_QUESTION_SELECT,
            });
           dispatch({
                type: SG_GET_ALL_SURVEY_QUESTION_SELECT,
            });
          
            dispatch({
                type: SG_GET_SURVEY_STRUCTURES,
                payload: `organization=${organizationId}`,
            });
        }
    }, [dispatch, organizationId]);
 
   useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_ORGANIZATION_STYLE,
            });
        }
    }, [dispatch, organizationId, stylingMsg]);

    const { get_organizations,
//        get_survey_question_select,
//        get_survey_question_all_select,
        get_survey_structure, } = useSelector(
            (state) => ({
                get_organizations: state.organizations,
                get_survey_question_select: state.surveyquestion.survey_question_select,
                get_survey_question_all_select: state.surveyquestion.survey_question_all_select,
                get_survey_structure: state.surveystructure.survey_structure,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationDetails(get_organizations?.[organizationId])
        setServiceList(get_organizations?.[organizationId]?.services_enabled)
        setStylingList(get_organizations?.[organizationId]?.styling)
        setOrganizationStylingSelect(get_organizations?.organization_style)
    }, [get_organizations, organizationId]);

    useEffect(() => {
        setResults(get_survey_structure?.results);
    }, [get_survey_structure]);

/*    function UpdateDetails() {
        setUpdateValue(false)
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    services_enabled: serviceList,
                    styling: stylingList
                }
            });
        }
    }
*/

    useEffect(() => {
//        setDomainSelected(SUB_DOMAIN_NAMES.filter(sd => sd.value === organizationDetails?.sub_domain)?.[0])
//        setPaymentStatusSelected(ORG_PAID_STATUS.filter(sd => sd.value === organizationDetails?.payment_status)?.[0])
        setTimeZone(CommonTimezones.filter(sd => sd.value === organizationDetails?.time_zone)?.[0])
    }, [organizationDetails]);

    function EditOrgName() {
        if (organizationDetails?.id) {
            setOpenOrgEdit(true)
        }
    }

    function ReplaceStyling(e) {
        setStylingMsg(false)
        setStylingList(false)
        if (e?.styling) {
            setStylingList(e?.styling)
        } else {
            setStylingList(false)
        }
    }
    
    function UpdateCurrentStyling() {
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    styling: stylingList,
                }
            });
            setStylingMsg({
                header: "Styling Changed",
                content: JSON.stringify(stylingList)
    
            })
        }
    }
    
    return (
        <>
            <OnBoarding organizationId={organizationId} />

            <Header as='h2'>
                Home
            </Header>

            <UpdateOrg organizationDetails={organizationDetails} setOpenOrgEdit={setOpenOrgEdit} />

            <SectionDiv>
                Copy settings from another organization <Icon
                    style={{ cursor: "pointer" }}
                    onClick={EditOrgName} name='external alternate' />
                <Image
                    floated='right'
                    size='mini'
                    src={organizationDetails?.logo}
                />
            </SectionDiv>

            <Modal closeIcon onClose={() => setOpenOrgEdit(false)} open={openOrgEdit}>
                <Modal.Header>Organization Details</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <SingleRowContent>
                            <SectionCell>
                                <label>Select an Organization Configuration to Copy</label>
                                <Select
                                    name="OrganizationStyling"
                                    options={organizationStylingSelect}
                                    isClearable
                                    onChange={ReplaceStyling}
                            //      value={orgStyleSelected}
                                    getOptionLabel={(answers) => answers.name}
                                    getOptionValue={(answers) => answers.id}
                                />
                            </SectionCell>
                            <SectionCell>
                                <Button
            //                            disabled={orgStyleSelected ? !Object.values(orgStyleSelected).length > 0 : true}
                                        style={{ marginTop: "20px" }}
                                        onClick={UpdateCurrentStyling}>
                                        Update Configuration
                                </Button>
                            </SectionCell>
                        </SingleRowContent>                        
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  min-height: 110px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};
`

const Container = styled.div`
  display: flex;
`

const SemiImportant = styled.div`
  margin:10px 0px;
  font-size: x-large;
`

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`

const SectionCellDropDown = styled.div`
    padding: 30px 15px;
    min-width: 350px;
    z-index:900;
`

const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`

const ImageBox = styled.image`
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;  
`
