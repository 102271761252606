import React, { useEffect, useState } from 'react';
import Select from "react-select";

import styled from "styled-components";
import { useDispatch } from "react-redux";
import { UPDATE_ORGANIZATION } from "constants/actions";

import { Message, Button, Input } from 'semantic-ui-react'
import { BasicErrorMessage } from "utilities/ErrorMessages"
import { CommonTimezones } from "data/timezone";


const Details = ({ organizationDetails, setOpenOrgEdit }) => {
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState([]);
    const [pristine, setPristine] = useState(true)
    const [valueChanged, setValueChanged] = useState(false)

    const [name, setName] = useState(organizationDetails?.name);
    const [timeZone, setTimeZone] = useState(CommonTimezones.filter(sd => sd.value === organizationDetails?.time_zone)?.[0]);

    function UpdateValue() {
        setPristine(false)
        if (formErrors.length === 0) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationDetails.id,
                    name: name,
                    time_zone: timeZone.value,
                }
            });
            setPristine(true)
            setOpenOrgEdit(false)
        }
    }

    useEffect(() => {
        if (organizationDetails?.name) {
            setName(organizationDetails?.name)
            setTimeZone(CommonTimezones.filter(sd => sd.value === organizationDetails?.time_zone)?.[0])
        }
    }, [organizationDetails]);

    useEffect(() => {
        const ErrorList = []
        if (!name) {
            ErrorList.push("Enter a Name")
        }
        setFormErrors(ErrorList)
    }, [name, valueChanged]);

    function ChangeFormValues(e, name) {
        if (name === 'name') {
            setName(e.target.value)
        }
        setValueChanged(e.target.value + name)
    }

    return (
        <>
            {formErrors.length > 0 && !pristine &&
                <Message negative
                    header='Please provide required values.'
                    list={formErrors}
                />
            }
            <SectionDiv>
                <BasicErrorMessage />
                <FormRow>
                    <FormLabel>
                        <LabelHead>Organization Name</LabelHead>
                    </FormLabel>
                    <FormInput>
                        <Input
                            fluid
                            name="name"
                            defaultValue={name}
                            onChange={(e) => ChangeFormValues(e, "name")}
                        />
                    </FormInput>
                </FormRow>
                <FormRow>
                <FormLabel>
                        <LabelHead>Time Zone</LabelHead>
                    </FormLabel>
                    <FormInput>
                        <Select
                            name="timezone"
                            options={CommonTimezones}
                            onChange={setTimeZone}
                            value={timeZone}
                        />
                    </FormInput>
                </FormRow>
                </SectionDiv>
                    <FormLabel>
                        <LabelHead></LabelHead>
                    </FormLabel>
                    <FormInput>
                        <Button 
                            color="green"
                            onClick={UpdateValue}>
                            Update
                        </Button>
                    </FormInput>

                <SectionDiv>
                </SectionDiv>
        </>
    );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const LabelHead = styled.label`
font-weight: 700;
display: block;
`

const FormRow = styled.div`
  display: flex;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  max-width: 400px;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
  max-width: 400px;

`
