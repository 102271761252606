

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'
import { format } from 'date-fns'

const EmployeeLogValue = ({ rows }) => {
    const navigate = useNavigate()

    return (
        <>
            <Table selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Type Of</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i}  onClick={() => navigate(`/app/employee-log/details/${row?.id}`)} style={{ cursor: 'pointer' }}>
                            <Table.Cell>{row?.type_of_log_text}</Table.Cell>
                            <Table.Cell>{row?.created_at && format(new Date(row?.created_at), 'MMM. dd, yyyy hh:mm a')}</Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeLogValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeLogValue;

