import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';
import { Header, Grid, Button } from 'semantic-ui-react'
import { useNavigate, useLocation } from "react-router-dom";


/* 
Here are some comments that were inheritied with the software. I'm not exactly sure how to parse them, but they may provide some clues. Add them to the subheader
below when they can be clarified in a meaningful way - Travis

    The external name is what the system would look for
    and if a match is found would map it with the name in [Name] field
    If both the names are same just use the name field alone, which would
    be the fallback check if no match is found in external name
    works for API and CSV upload
*/

function EmployeeRecordValue() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Header as='h2' style={{ marginTop: "50px" }}>
                        Employee Records
                        <Header.Subheader>
                            <p>
                                Create and manage custom data points for an organization's employees. Examples include: employee salary, sick days, performance, etc.
                            </p>
                             <p>
                                Employee record data can change over time and is stored within each employee's survey to memorialize their information when they are evaluated.
                            </p>
                            <br/>
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    {(location.pathname !== '/app/employee-record-value/new') &&
                        <Button primary
                            onClick={() => navigate(`/app/employee-record-value/new`)}
                        >Add New</Button>
                    }

                </Grid.Column>
            </Grid.Row>

            <Routes>
                <Route path="/" element={<List />} />
                <Route path="new" element={<AddEdit />} />
                <Route path="details/:id" element={<Details />} />
            </Routes>
        </>
    );
}

export default EmployeeRecordValue;
