import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { RowCheckList, RowObjectList } from "organizations/Components";

import { Card, Image, Button, Header, Message, Input } from "semantic-ui-react";

import { GET_AM_ORGANIZATION, UPDATE_ORGANIZATION } from "constants/actions";

import ClickToCopy from "utilities/ClickToCopy";

function RowCard({ row }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [gsheetId, setGsheetId] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);

  function SelectedOrg() {
    dispatch({
      type: GET_AM_ORGANIZATION,
      payload: row,
    });
    navigate(`/app/organizations/details/${row?.id}`);
  }
  const utcDateTime = utcToZonedTime(new Date(), "UTC");
  const [localDateTime, setLocalDateTime] = useState(
    zonedTimeToUtc(new Date(), "US/Eastern")
  );



  // function updateGsheetId(value) {
  //   setPristine(false);
  //   if (formErrors.length === 0) {
  //     // ["gsheet_id"]
  //     let _ExtExtrDet = Object.keys(row?.styling)
  //       //Filter Object with key contanis "gsheet_id"
  //       .filter((key) => key !== "gsheet_id")
  //       .reduce((obj, key) => {
  //         return Object.assign(obj, {
  //           [key]: row?.styling[key],
  //         });
  //       }, {});

  //     _ExtExtrDet["gsheet_id"] = gsheetId;
  //     console.log(_ExtExtrDet);
  //     dispatch({
  //       type: UPDATE_ORGANIZATION,
  //       payload: {
  //         id: row?.id,
  //         styling: _ExtExtrDet,

  //       },
  //     });
  //     setPristine(true);
  //   }
  // }

  useEffect(() => {
    if (row?.time_zone) {
      const date = new Date();
      const timeZone = row?.time_zone;
      const zonedDate = utcToZonedTime(date, timeZone);
      // const pattern = 'dd.MMM.yyyy HH:mm XXX (z)'
      // const output = format(zonedDate, pattern)
      setLocalDateTime(zonedDate);
    }
    // setGsheetId(row?.styling?.gsheet_id);
  }, [row]);
  // console.log(stylingList)
  useEffect(() => {
    const ErrorList = [];
    if (!gsheetId) {
      ErrorList.push("Enter a Gheet Id");
    }

    setFormErrors(ErrorList);
  }, [gsheetId, valueChanged]);

  // function ChangeFormValues(e, name) {
  //   // console.log(name, e.target.value)

  //   if (name === "gsheet_id") {
  //     setGsheetId(e.target.value);
  //   }
  //   setValueChanged(e.target.value + name);
  // }

  

  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header>{row?.name}</Card.Header>
          <Container>
            <SingleRowContent>
              <SectionCell>
                <p>
                  Created{" "}
                  {row?.created_at &&
                    format(new Date(row?.created_at), "dd-MMM-yyyy")}
                </p>
                <p>Domain {row?.sub_domain_text}</p>
              </SectionCell>
              <SectionCell>
                <p>UTC Time {format(utcDateTime, "dd-MMM-yyyy hh:mm")}</p>
                <p>
                  Local Time{" "}
                  {localDateTime &&
                    format(localDateTime, "dd-MMM-yyyy hh:mm XXX (z)")}
                </p>
              </SectionCell>
              <SectionCell>{row?.time_zone}</SectionCell>
            </SingleRowContent>
          </Container>

          <Container>
            <LeftHalf>
              <RowData>
                <RowLine>{RowCheckList(row?.services_enabled)}</RowLine>
                <RowLine>{RowObjectList(row?.styling)}</RowLine>
              </RowData>
            </LeftHalf>
            <RightHalf>
              <Image floated="right" size="mini" src={row?.logo} />
            </RightHalf>
          </Container>

          {/* <ListContainer>
            <Header as="h3">Generate Google Sheet with questions</Header>
            <span>
              Share the google worksheet with survey-response-creating@deep-lore-365516.iam.gserviceaccount.com
              <ClickToCopy
                textToCopy={
                  "survey-response-creating@deep-lore-365516.iam.gserviceaccount.com"
                }
              />
            </span>
            <p>Copy paste the google sheet id below </p>
            <div>
              {formErrors.length > 0 && !pristine && (
                <Message
                  negative
                  //   onDismiss={clearErrors}
                  header="We need these details!"
                  list={formErrors}
                />
              )}
              <FormRow>
                <FormLabel>
                  <LabelHead>Google Sheet Id</LabelHead>
                </FormLabel>
                <FormInput>
                  <Input
                    fluid
                    name="gsheet_id"
                    defaultValue={gsheetId}
                    onChange={(e) => ChangeFormValues(e, "gsheet_id")}
                  />
                </FormInput>
                 <Button floated="right" onClick={updateGsheetId} positive>
                Create / Update
              </Button>
              </FormRow>

             
            </div>
          </ListContainer> */}

          
            <SingleRowButtons>
              <Button onClick={SelectedOrg}>More Details</Button>

              <Button onClick={() => navigate(`/app/demo-survey-response/organization/${row?.id}`)}>Demo Responses</Button>
            </SingleRowButtons>
        
        </Card.Content>
      </Card>
    </>
  );
}

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const Container = styled.div`
  display: flex;
`;
const ListContainer = styled.div`
  margin: 0px 15px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`;

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`;

const RowLine = styled.span`
  max-width: 400px;
  // font-size: 100%;
  margin: 0 10px;
  display: inline-block;
`;

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`;

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SingleRowButtons = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

const SectionCell = styled.div`
  padding: 30px 15px;
  min-width: 350px;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
