import React from "react";
import PropTypes from "prop-types";

import { Table, Card } from "semantic-ui-react";

const CategoryTags = ({ rows }) => {
  return (
    <>
    {rows?.length > 0 ? 
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Value</Table.HeaderCell>
            <Table.HeaderCell width={1}>Priority</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows && rows.map((row, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{row?.name}</Table.Cell>
                <Table.Cell>{row?.value}</Table.Cell>
                <Table.Cell>{row?.priority}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
        : <NoRecords message={"No category values"} />

}
    </>
  );
};
const NoRecords = ({ message }) => {
  return (<>
      <Card>
          <p style={{ padding: '20px' }}>{message}</p>
      </Card>
  </>)
}
CategoryTags.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default CategoryTags;
