import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Blank from "assets/images/empty/user.svg"

import Settings from "assets/images/nav/settings.svg";

import OrganizationSelect from "organizations/OrganizationSelect";


const TopBar = () => {

	const [employee, setEmployee] = useState(false);
	const [organization, setOrganization] = useState(false);
	const [checkForStaff, setCheckForStaff] = useState(false);

	const [selectedOrg, setSelectedOrg] = useState(false);

	const [resetPageLoad, setResetPageLoad] = useState(false);


	const IsStaff = useSelector((state) => state.auth?.is_staff, shallowEqual)

	/* 	const [pageTitle, setPageTitle] = useState(false);
		const get_page_title = useSelector((state) => state.audit?.anchor, shallowEqual)
	
		  useEffect(() => {
			setPageTitle(get_page_title);
		  }, [get_page_title]);
	 */
	useEffect(() => {
		setCheckForStaff(true)
	}, [IsStaff]);

	useEffect(() => {
		if (typeof IsStaff == "boolean") {
			// variable is a boolean
			if (IsStaff) {
				setCheckForStaff(true)
			}
			// console.log("IsStaff Boolean")
		}
		if (typeof IsStaff == "string") {
			// variable is a boolean
			if (IsStaff === 'true') {
				setCheckForStaff(true)
			}
			// console.log("IsStaff Boolean")
		}

	}, [IsStaff]);

	const { get_employee, get_auth, get_organizations, get_organization,
		get_SelectedOrg } = useSelector(
			(state) => ({
				get_employee: state.employees,
				get_auth: state.auth,
				get_organizations: state.organizations,
				get_organization: state.organization,
				get_SelectedOrg: state.selectedOrg,
			}),
			shallowEqual
		);

	// console.log(get_SelectedOrg.organization)

	useEffect(() => {
		if (get_employee) {
			setEmployee(get_employee[get_auth?.employee_id]);
		}

		//		console.log(get_employee?.employees[])
		if (get_auth?.token) {
			setOrganization(get_organizations[get_auth.organization_id])
			setSelectedOrg(get_SelectedOrg)
		}
	}, [get_employee, get_auth, get_organizations, get_SelectedOrg]);

	return (
		<Container>
			<div>
				<OrgHeader>
					{selectedOrg?.organization?.name}
				</OrgHeader>
			</div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{employee ?
					<EmployeeContainer>
						<Text>
							<Name>{employee.first_name + " " + employee.last_name} </Name>
						</Text>

						<Avatar
							src={employee?.picture ? employee.picture : Blank}
						/>
					</EmployeeContainer>
					: ""}
				<Line />

				<Link to="/settings/member">
					<Popup content='Profile Settings' trigger={<Image src={Settings} />} />
				</Link>

				<Link to="/account/logout">
					<Popup content='Log Out' trigger={<Icon name="log out" />} />
				</Link>
			</div>

		</Container>
	)
}



export default TopBar



const Container = styled.div`
	width:100%;
	position:fixed;
	top:0px;
	height:50px;
	border-bottom:1px solid  #e9e9e9;
	z-index: 998;
	background-color: white;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding-right:20px;
	padding-left:55px;
	font-size:20px;

	a{
		color:#a5a5a5;
	}
`

const Avatar = styled.img`
	height:40px;
	width:40px;
	border-radius:20px;
	
`

const Text = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-right:15px;
`

const Name = styled.div`
	font-size:14px;
	font-weight:bold;
	margin-bottom:-5px;
`

const Role = styled.div`
	font-size:12px;
	color:#a5a5a5;
`

const EmployeeContainer = styled.div`
	display:flex;
	align-items:center;
	margin-right:20px;
`

const Line = styled.div`
	height:100%;
	width:1px;
	background-color:#a5a5a5;
	margin-right:20px;
`

const Image = styled.img`
	height:20px;
	margin-right:20px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:22px;
	font-weight:bold;
	color:#a5a5a5;
`

const Upgrade = styled.span`
  display: flex;
  font-size:14px;
  padding: 5px 10px 5px 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
  border-radius: 15px;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  justify-content: space-between;
`;
const OrgHeader = styled.p`
  padding: 1rem;
  font-size: 26px;
  font-weight: 900;
  padding: 15px 10px 15px 10px;
`;