import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import List from './List.js';
import Details from './Detail.js';
import { Header, Icon } from 'semantic-ui-react'

function CategoryStructure() {
    const navigate = useNavigate();
    function BackArrow() {
        navigate('/app/category-compliance/')
    }

    return (
        <>
            <Header as='h2' style={{ marginTop: "20px" }}>
                Category Setup
                <Header.Subheader>
                    Create and manage organization and demographic categories for data segmentation
                </Header.Subheader>
            </Header>
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="details/:id" element={<Details />} />
                <Route path="create" element={<Details />} />
            </Routes>
        </>
    );
}

export default CategoryStructure;
