import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { UPDATE_ORGANIZATION, } from "constants/actions";

// Components
import styled from "styled-components";
import { RowObjectInputEdit, } from "../Components";
import { Button, Header, Message } from 'semantic-ui-react'


const OrganizationStyles = ({ organizationId }) => {

    const dispatch = useDispatch();

    const { get_organizations } = useSelector(
        (state) => ({
            get_organizations: state.organizations,
        }),
        shallowEqual
    );
    
    const [stylingList, setStylingList] = useState(get_organizations?.[organizationId]?.styling)
    const [stylingMsg, setStylingMsg] = useState(false)
    const [updateValue, setUpdateValue] = useState(false)

    function UpdateDetails() {
        setUpdateValue(false)
        if (organizationId) {
            dispatch({
                type: UPDATE_ORGANIZATION,
                payload: {
                    id: organizationId,
                    styling: stylingList
                }
            });
        }
    }
    
    useEffect(() => {
        setStylingList(get_organizations?.[organizationId]?.styling)
    }, [get_organizations, organizationId]);

    return (
        <>
            <Header as='h2'>
                Organization Styles
            </Header>
            <Header.Subheader>
                    Specify custom styles for the organization, if desired. <br/>
                    Creating a style called 'demo' and setting it to 'true' enables the Data Science tab in the nuLogic portal.
            </Header.Subheader>

           {stylingMsg && (
                <Message
                    positive
                    onDismiss={() => setStylingMsg(false)}
                    header={stylingMsg.header}
                    content={stylingMsg.content}
                />
            )}
            <SectionDiv>
                <SectionDiv>
                    <RowObjectInputEdit
                        stylingList={stylingList}
                        setStylingList={setStylingList}
                        setUpdateValue={setUpdateValue}
                    />
                    <Button
                        color='violet'
                        onClick={UpdateDetails}
                        disabled={!updateValue}>Update</Button>
                </SectionDiv>
            </SectionDiv>
        </>
    );
};

export default OrganizationStyles

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  min-height: 110px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};
`
